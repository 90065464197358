import { useState } from 'react';
import apiClient from '../api/axios';
import { useAuth } from '../context/AuthProvider';

const LoginHook = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { setToken } = useAuth();

  const loginUser = async (credentials) => {
    setIsLoading(true);
    try {
      const response = await apiClient.post("/user/login", JSON.stringify(credentials));

      if (response.data.data.accessToken) {
        setToken(response.data.data.accessToken);
        setIsLoggedIn(true);
        setErrorMessage('');
      } else {
        throw new Error('No access token received');
      }
      return response.data;
    } catch (error) {
      handleError(error);
      throw error; 
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = (error) => {
    if (!error.response) {
      setErrorMessage('No Server Response');
    } else if (error.response.status === 400) {
      setErrorMessage('Missing Username or Password');
    } else if (error.response.status === 401) {
      setErrorMessage('Unauthorized');
    } else {
      setErrorMessage('Login Failed!');
    }
  };
  
  const oAuthLogin = () => {
    const googleOAuth = `${process.env.REACT_APP_AUTH_URL}/auth/google`;
    window.open(googleOAuth,'_self');
  }

  return {
    isLoggedIn, 
    isLoading, 
    errorMessage,
    loginUser,
    oAuthLogin
  };
};


export default LoginHook;
