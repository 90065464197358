import { useState, useEffect } from "react";
import axios from '../api/axios';

const EmailValidation = (email) => {
  const [isEmailAvailable, setIsEmailAvailable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (email) {
      const checkEmailAvailability = async () => {
        setIsLoading(true);
        try {
          const credentials = {email}

          const response = await axios.post("/user/emailcheck", JSON.stringify(credentials), {
            withCredentials: true
          })
          .then(response => {
            setIsEmailAvailable(response.data.result !== false);
            return response.data; 
          })
          .catch(error => {
            console.error('Error:', error); 
          });
          
          return response
        } catch (error) {
          console.error("Error checking email availability:", error);
        } finally {
          setIsLoading(false);
        }
      };

      checkEmailAvailability();
    }
  }, [email, isEmailAvailable]);

  return { isEmailAvailable, isLoading };
};

export default EmailValidation;
