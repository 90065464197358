import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import EastIcon from '@mui/icons-material/East';
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Dropdown from "../components/Dropdown";
import EmailValidation from "../hooks/EmailValidation";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import useSendOtp from "../hooks/SendOtp";



export default function ForgotPass() {

  // eslint-disable-next-line
 const [error, setError] = React.useState("");
  // eslint-disable-next-line
 const [selectedLanguage, setSelectedLanguage] = React.useState("EN");
  const navigate = useNavigate();
  const { sendOtp } = useSendOtp();
  const [email, setEmail] = useState("");
  const { isEmailAvailable } = EmailValidation(email);

  
  const handleSubmit = async (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);

    try {
      sendOtp(email).then((res) => {
        if(res.result === true) {
          navigate('/verify', { state: { email, type: 1 } });
        } else {
          setError("Invalid OTP. Please try again.");
        }
      });
    

    } catch(error) {
      setError(
        "An error occurred during verification."
      );
    }
  };


  return (
    
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 4,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              container
              justifyContent="flex-end"
              sx={{ marginTop: "-20px" }}
            >
              <Grid item>
                <Dropdown setSelectedLanguage={setSelectedLanguage} />
              </Grid>
            </Grid>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 20 }}
            >
              <Typography
                component="h1"
                variant="h6"
                sx={{ alignItems: "left", width: "100%" }}
              >
                Forgot Password?
              </Typography>
              <Typography sx={{ fontSize: "10px", fontWeight: "400" }}>
                Don’t worry! It happens. Please enter the email associated with
                your account.
              </Typography>

                <InputLabel shrink={false} htmlFor={"email"} sx={{ mt: 4 }}>
                  <Typography variant="body2" sx={{fontSize: "14px", fontWeight: "bold"}}>Email*</Typography>
                </InputLabel>
                <TextField
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="joe@gmail.com"
                  required
                  sx={{
                    width: "100%",
                    // alignItems: "center",
                    backgroundColor: "#F5F5F5",
                    borderRadius: "4px",
                    padding: 1,
                    border: isEmailAvailable === true ? 1 : 0,
                    borderColor: isEmailAvailable === true ? "green" : "red",
                  }}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    sx: { fontSize: "small" },
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: "small" },
                  }}
                  name="email"
                  autoComplete="email"
                  size="small"
                />
                {(isEmailAvailable === true && email !== "") && (
                  <li style={{ color: "green", fontSize: "10px" }}>
                    This email address is valid.
                  </li>
                )}

              {(isEmailAvailable === false && email !== "") && (
                  <li style={{ color: "red", fontSize: "10px" }}>
                    This email address is not registered/valid.
                  </li>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    color: "white",
                    backgroundColor: "grey",
                    "&:hover": {
                      backgroundColor: "darkgrey",
                    },
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  Send Code
                </Button>

              <Grid container direction="row" justifyContent="start">
                <Grid item>
                  <Typography variant="body2" sx={{ fontSize: "14", fontWeight: "600", color: "#A8A8A8"}}>Remember Password?</Typography>
                </Grid>
                <Grid item sx={{ ml: 5}}>
                  <Link href="/login" variant="body2" underline="none">
                  <Typography sx={{ display: "flex", fontWeight: "600", color: "#000", alignItems: "center", fontSize: "14px" }}>
                    Login
                    <EastIcon sx={{ ml: 2 }}/>
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
  );
}
