import { useState } from "react";
import axios from '../api/axios';

export default function useResetPassword() {
  const [isPasswordResetSuccessful, setIsPasswordResetSuccessful] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


    const resetPassword = async (password, confPassword) => {
      if (!confPassword) {
        return;
      }

      setIsLoading(true);
      try {

        const credentials = { password, confPassword };

        const response = await axios.post("/user/resetpassword", JSON.stringify(credentials), {
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          withCredentials: true
        })
        .then(response => {
            if (response.status === 200) {
              setIsPasswordResetSuccessful(true);
              setErrorMessage("");
            } else {
              throw new Error(response.msg || "Password reset failed");
            }
            return response.data; 
        })


      return response;

      } catch (error) {
        setIsPasswordResetSuccessful(false);
        setErrorMessage("Error resetting password");
      } finally {
        setIsLoading(false);
      }
    };


  return {
    isPasswordResetSuccessful,
    isLoading,
    errorMessage,
    resetPassword
  };
};

