import { useState, useEffect } from 'react';
import axios from '../api/axios';

const UsernameValidation = (username) => {
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    if (username) {
      const checkUsernameAvailability = async () => {
        setIsLoading(true);
        try {
          const response = await axios.post("/user/usernamecheck", JSON.stringify({username}), {
            withCredentials: true
          })
          .then(response => {
            setIsUsernameAvailable(response.data.result !== true);
            return response.data; 
          })
          .catch(error => {
            console.error('Error:', error); 
          });
          
          return response;

        } catch (error) {
          console.error('Error checking username availability:', error);
        } finally {
          setIsLoading(false);
        }
      };
      
      checkUsernameAvailability();
    }
  }, [username]); 
  
  return { isUsernameAvailable, isLoading };
};

export default UsernameValidation;
