import "./App.css";
// import AppRouter from './components/AppRouter';
import { createTheme, ThemeProvider } from "@mui/material";
import Routes from "./routes";
import { AuthProvider } from "./context/AuthProvider";

const theme = createTheme({
  typography: {
    fontFamily: ["Raleway"].join(","),
    button: {
      textTransform: "none",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
