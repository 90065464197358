import * as React from "react";
import { useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  MenuItem,
  Button,
  Select,
  InputLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ToggleButton from "../ToggleButton";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SettingTabs = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => setValue(newValue);

  const [language, setLanguage] = useState("");
  const [timezone, setTimezone] = useState("");

  const handleLanguageChange = (event) => setLanguage(event.target.value);
  const handleTimezoneChange = (event) => setTimezone(event.target.value);
  const handleSaveChanges = () => console.log("Language:", language, "Timezone:", timezone);

  const timezones = [
    { label: "GMT-12:00", value: "Etc/GMT-12" },
    // Add all other timezones here
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          mb: 2,
          p: 2,
          borderRadius: 1,
          boxShadow: 1,
          backgroundColor: "background.paper",
        }}
      >
        <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Notifications" {...a11yProps(1)} />
        </Tabs>

        <CustomTabPanel value={value} index={0}>
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            width={isMobile ? "100%" : "40%"}
          >
            <InputLabel id="langlabel">Language</InputLabel>
            <Select
              labelId="langlabel"
              id="select"
              value={language}
              onChange={handleLanguageChange}
              fullWidth
            >
              <MenuItem value="English">English (Default)</MenuItem>
              <MenuItem value="Spanish">Spanish</MenuItem>
            </Select>
            <InputLabel id="timelabel">Timezone</InputLabel>
            <Select
              labelId="timelabel"
              id="select"
              value={timezone}
              onChange={handleTimezoneChange}
              fullWidth
            >
              {timezones.map((zone) => (
                <MenuItem key={zone.value} value={zone.value}>
                  {zone.label}
                </MenuItem>
              ))}
            </Select>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveChanges}
              sx={{ width: isMobile ? "100%" : "70%" }} 
            >
              Save Changes
            </Button>
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <ToggleButton
              label="Message"
              labelPlacement="end"
              sx={{ marginLeft: isMobile ? 0 : '10px' }}
            />
            <ToggleButton
              label="Task Update"
              labelPlacement="end"
              sx={{ marginLeft: isMobile ? 0 : '10px' }}
            />
            <ToggleButton
              label="Task Deadline"
              labelPlacement="end"
              sx={{ marginLeft: isMobile ? 0 : '10px' }}
            />
            <ToggleButton
              label="Mentor Help"
              labelPlacement="end"
              sx={{ marginLeft: isMobile ? 0 : '10px' }}
            />
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default SettingTabs;
