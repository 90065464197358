import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Avatar,
  Stack,
  Grid,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const TaskProgressCard = ({
  taskName,
  taskDescription,
  progress,
  imageUrl,
  daysToDeadline,
  assignedTo,
}) => {
  return (
    <Card sx={{ width: '100%', height: 'auto' }}>
      <img
        src={imageUrl}
        alt={taskName}
        style={{
          width: '100%',
          height: '150px',
          objectFit: 'cover',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        }}
      />
      <CardContent>
        <Typography variant="h6" component="div" noWrap={false}>
          {taskName}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap={false}>
          {taskDescription}
        </Typography>

        <Box sx={{ my: 2 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: 8,
              borderRadius: 4,
              backgroundColor: '#BAC8FF', 
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#546FFF',
                borderRadius: 4,
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1,
          }}
        >
          <Typography variant="body2" color="text.secondary">
            Progress
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {progress}%
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <AccessTimeIcon />
            <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
              {daysToDeadline} days to deadline
            </Typography>
          </Stack>

          <Grid container spacing={-2} alignItems="center" justifyContent="flex-end">
            {assignedTo.map((person, index) => (
              <Grid item key={index}>
                <Avatar alt={person.name} src={person.profilePhotoUrl} sx={{ marginLeft: '-10px', border: '2px solid #fff', width: 28, height: 28 }} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TaskProgressCard;
