import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Dropdown from "../components/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import OTP from "../components/OtpInput";
import OtpTimer from "../hooks/OtpTimer";
import useVerifyOtp from "../hooks/VerifyOtp";


export default function Verify() {
  // eslint-disable-next-line
  const [selectedLanguage, setSelectedLanguage] = React.useState("EN");
  const location = useLocation();
  const navigate = useNavigate(); 
  const [otp, setOtp] = React.useState(""); 
  const [error, setError] = React.useState("");
  const email = location.state?.email;
  const type = location.state?.type;


  React.useEffect(() => {
    if(type === undefined) {
      navigate('/login')
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const { verifyOtp } = useVerifyOtp();

  const handleSubmit = async (event) => {

    event.preventDefault();

    try {
      const response = await verifyOtp(otp, type);

      if (response.result === true) {
        if(response.errno === 107) { // account verified
          navigate("/login" , {
            state: {
              successMessage: "Account verified! Redirect to dashboard",
            }
          });
        } else if(response.errno === 108) { // reset code is valid
          navigate("/reset-password", { state: { email, type: 1 } });
        }
      } else {
        setError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      setError(
        "An error occurred during verification."
      );
    }
  };

  return (
    
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 4,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              container
              justifyContent="flex-end"
              sx={{ marginTop: "-20px" }}
            >
              <Grid item>
                <Dropdown setSelectedLanguage={setSelectedLanguage} />
              </Grid>
            </Grid>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 20 }}
            >
              <Typography
                component="h1"
                variant="h6"
                sx={{ alignItems: "left", width: "100%" }}
              >
                Please check your email
              </Typography>
              <Typography sx={{ fontSize: "13px" }}>
                We’ve sent a code to {email}
              </Typography>


              <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, mt: '15px', alignItems: "center"}} >
                <OTP separator={<span></span>} value={otp} onChange={setOtp} length={6} />
              </Box>
              {error && (
                <Typography color="error" sx={{ mt: 2 }}>
                  {error}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  color: "white",
                  fontSize: "12px",
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "#414141",
                  },
                  width: "100%",
                }}
              >
                Verify
              </Button>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <OtpTimer />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>

  );
}
