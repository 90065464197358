import { useState } from 'react';
import axios from '../api/axios';

const RegisterHook = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const registerUser = async (userData) => {
  
    setIsLoading(true);
    try {

      const response = await axios.post("/user/register", JSON.stringify(userData), {
        withCredentials: true
      })
      .then(response => {
        if (response.data.status) {
          setIsRegistered(true);
          setErrorMessage('');
        } else {
          setErrorMessage(response.data.error || 'Failed to register');
        }
        return response.data; 
      })
      .catch(error => {
        console.error('Error:', error); 
      });
      
      return response;
    } catch (error) {
      setErrorMessage('An error occurred during registration');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isRegistered,
    isLoading,
    errorMessage,
    registerUser,
  };
};

export default RegisterHook;
