import * as React from "react";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  InputLabel,
  Divider,
  Snackbar,
  Alert,
  IconButton
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PasswordInput from "../components/passwordInput";
import GoogleIcon from "@mui/icons-material/Google";
import XIcon from "@mui/icons-material/X";
import GitHubIcon from "@mui/icons-material/GitHub";
import DoneIcon from "@mui/icons-material/Done";
import Dropdown from "../components/Dropdown";
import LoginHook from "../hooks/LoginHook";

export default function Login() {
  const userRef = useRef();
  const navigate = useNavigate();
  // eslint-disable-next-line 
  const { isLoading, errorMessage, loginUser, oAuthLogin } = LoginHook();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // eslint-disable-next-line 
  const [selectedLanguage, setSelectedLanguage] = useState("EN");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const location = useLocation();
  const successMessage = location.state?.successMessage;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      console.error("Please fill out all required fields.");
      return;
    }

    const credentials = { username, password };

    try {
      const res = await loginUser(credentials);

      if (res.errno === 0) { // account verified
        const email = res.data.email;
        console.log('verified');
        navigate('/dashboard', { state: { email, type: 1 } });
      } else if (res.errno === 105) { // account unverified
        const email = res.data.email;
        navigate('/verify', { state: { email, type: 0 } });
      } else if (res.errno === 109) { // wrong user/password
        console.log('show snackbar');
      } else {
        console.log('error');
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    if (successMessage) {
      setOpenSnackbar(true);
    }
  }, [successMessage]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box sx={{ my: 4, mx: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Grid container justifyContent="flex-end" sx={{ marginTop: "-20px" }}>
            <Grid item>
              <Dropdown setSelectedLanguage={setSelectedLanguage} />
            </Grid>
          </Grid>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 20 }}>
            <Typography component="h1" variant="h6" sx={{ alignItems: "left", width: "100%" }}>
              Welcome
            </Typography>
            <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>
              Sign in to Solulu.xyz
            </Typography>
            <InputLabel shrink={false} htmlFor={"username"} sx={{ mt: 4 }}>
              <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "800" }}>Username*</Typography>
            </InputLabel>
            <TextField
              id="username"
              sx={{ width: "35ch", backgroundColor: "#F5F5F5", borderRadius: "4px", padding: 1 }}
              required
              margin="normal"
              InputLabelProps={{ shrink: true, sx: { fontSize: "small" } }}
              variant="standard"
              InputProps={{ disableUnderline: true, sx: { fontSize: "small" } }}
              name="username"
              ref={userRef}
              placeholder="joe"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              autoComplete="username"
              autoFocus
              size="small"
            />
            <InputLabel shrink={false} htmlFor={"password"} sx={{ mt: 2 }}>
              <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "800" }}>Password*</Typography>
            </InputLabel>
            <PasswordInput
              password={password}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              handlePassword={(e) => setPassword(e.target.value)}
              validatePassword={false}
            />
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <FormControlLabel
                  control={<Checkbox
                    value="remember"
                    icon={<span style={{
                      borderRadius: "50%", backgroundColor: "#fff", border: "1px solid black",
                      display: "inline-block", width: 20, height: 20
                    }} />}
                    checkedIcon={<span style={{
                      borderRadius: "50%", backgroundColor: "#000", display: "inline-block",
                      width: 20, height: 20, textAlign: "center", color: "white"
                    }}>
                      <DoneIcon fontSize="small" />
                    </span>}
                  />}
                  label={<Typography variant="body2">Remember me</Typography>}
                />
              </Grid>
              <Grid item sx={{ marginTop: "8px" }}>
                <Link href="/forgot-password" variant="body2" underline="none"
                  sx={{ display: "flex", fontWeight: "400", color: "#000", alignItems: "center", fontSize: "14px" }}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, color: "white", backgroundColor: "grey", "&:hover": { backgroundColor: "darkgrey" } }}>
              Login
            </Button>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={5000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
              <Alert onClose={handleCloseSnackbar} severity="success">
                {successMessage}
              </Alert>
            </Snackbar>
            <Divider style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton sx={{ border: "1px solid #000", borderRadius: "50%", margin: 1 }} fontSize="small" onClick={oAuthLogin}>
                <GoogleIcon />
              </IconButton>
              <IconButton sx={{ border: "1px solid #000", borderRadius: "50%", margin: 1 }}>
                <XIcon />
              </IconButton>
              <IconButton sx={{ border: "1px solid #000", borderRadius: "50%", margin: 1 }}>
                <GitHubIcon />
              </IconButton>
            </Box>
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{ mt: 3 }}>
              {"Don't have an account?"}
              <Link color="inherit" href="/register" variant="body2" underline="none"
                sx={{ ml: 1, fontWeight: 600, fontSize: "13px" }}>
                Sign Up
              </Link>
            </Typography>
          </Box>
        </Box>
      </Grid>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </Grid>
  );
}
