import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Dropdown({ setSelectedLanguage }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState("EN");
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = (value) => () => { 
    setSelectedValue(value);
    setAnchorEl(null);
    setSelectedLanguage(value); 
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          border: '1px solid lightgrey',
          borderRadius: '20px', 
          textTransform: 'none', 
        }}
      >
        {selectedValue === "EN" ? "🇦🇺 EN" : "🇨🇳 CH"}
        <ExpandMoreIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose("EN")}>🇦🇺 EN</MenuItem> 
        <MenuItem onClick={handleClose("CH")}>🇨🇳 CH</MenuItem>
      </Menu>
    </div>
  );
}
