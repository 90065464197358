import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    position: "relative",
    margin: theme.spacing(2),
  },
  card: {
    background: 'linear-gradient(135deg, #141522 30%, #1e1e38 90%)',
    paddingTop: theme.spacing(7)
  },
  cardContent: {
    textAlign: "center",
    paddingBottom: theme.spacing(2)
  },
  cardActions: {
    justifyContent: "center",
  },
  iconContainer: {
    position: "absolute",
    top: -24,
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "50%",
  },
}));

const HelpCenter = () => {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer}>
        <HelpOutlineIcon className={classes.iconContainer} style={{ fontSize: 45, color: 'black' }} color="primary" />
      <Card className={classes.card} style={{borderRadius: '20px'}}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h7" component="div" color={'#ffffff'}>
            Help Center
          </Typography>
          <Typography variant="body2" color={"#ffffff"} style={{paddingTop: '15px'}}>
            Having Trouble in Learning. Please contact us for more questions.
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button size="large" variant="contained" fullWidth style={{margin:'10px', borderRadius: '10px', backgroundColor: '#ffffff', color: '#141522', fontSize: '10px'}}>
            Go To Help Center
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default HelpCenter;
