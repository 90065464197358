import * as React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

const HomeHeader = () => {
  return (
    <AppBar position="static" sx={{ backgroundColor: "white" }} elevation={0}>
      <Toolbar sx={{ position: "relative" }}>
        {/* Centered Box */}
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            gap: 2,
          }}
        >
          <Button sx={{ color: "black" }}>Products</Button>
          <Button sx={{ color: "black" }}>Why Solulu</Button>
          <Button sx={{ color: "black" }}>Developers</Button>
          <Button sx={{ color: "black" }}>Company</Button>
          <Button sx={{ color: "black" }}>Resources</Button>
        </Box>

        {/* Right Aligned Box */}
        <Box sx={{ marginLeft: "auto", display: "flex", gap: 2 }}>
          <Link to="/login">
            <Button sx={{ color: "black" }}>
              Login
              <NavigateNextRoundedIcon />
            </Button>
          </Link>
          <Button
            sx={{
              color: "white",
              backgroundColor: "black",
              borderRadius: "20px",
              padding: "6px 12px",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
          >
            Contact Sales
            <NavigateNextRoundedIcon />
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default HomeHeader;
