import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Avatar, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

const Headbar = ({ headerText, userSubtitle = '', notificationsCount, profilePhotoUrl }) => {
  return (
    <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000', boxShadow: 'none' }}>
      <Toolbar sx={{backgroundColor: 'white'}}>
        <Box sx={{ flexGrow: 1, backgroundColor: 'white'}}>
          <Typography variant="h4" component="div">
            {headerText}
          </Typography>
          {userSubtitle && (
            <Typography variant="body1" component="div">
              {userSubtitle}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit">
            <Badge badgeContent={notificationsCount} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Avatar alt="Profile Picture" src={profilePhotoUrl} sx={{ marginLeft: 2 }} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Headbar;
