import { useState } from "react";
import axios from '../api/axios';

export default function useSendOtp() {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(null);

  const sendOtp = async (email) => {
    setIsLoading(true);
    setError(null);

    const credentials = {email: email}

    try {
      const response = await axios.post("/user/forgotpassword", JSON.stringify(credentials), {
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        withCredentials: true
      })
      .then(response => {
          if (response.status === 200) {
            setIsVerified(true);
          } else {
            throw new Error(response.msg || "Failed to send forgot password code.");
          }
          return response.data; 
      })


      return response;

    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }

    
  };

  return { sendOtp, isLoading, isVerified, error };
}
