import * as React from 'react';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import PasswordChecklist from "react-password-checklist";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const PasswordInput = ({ idKey='' , password, handlePassword, validatePassword = true, label, match = false, checklistToggle = true}) => {
  // eslint-disable-next-line
 const [passwordAgain, setPasswordAgain] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const rules = ["minLength", "specialChar", "number", "capital"]

  if (match === true) {
    rules.push("match")
  }


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <InputLabel shrink={false} htmlFor={"password"}>
        <Typography variant="body2">{label}</Typography>
      </InputLabel>
      <TextField
        id={idKey}
        fullWidth
        margin="normal"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={handlePassword}
        required={true}
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          disableUnderline: true,
          sx: { fontSize: "small" },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        name={idKey}
        autoComplete="password"
        size="small"
        sx={{
          backgroundColor: "#F5F5F5",
          padding: 1,
          borderRadius: "4px",
        }}
        onFocus={() => setIsPasswordFocused(true)}
        onBlur={() => setIsPasswordFocused(false)}
      />

      {validatePassword && isPasswordFocused && checklistToggle && (
        <PasswordChecklist
          rules={rules}
          minLength={5}
          value={password}
          valueAgain={passwordAgain}
          validTextColor="green"
          invalidTextColor="grey"
          style={{ fontSize: "13px", transition: "height .3s" }}
          iconComponents={{
            ValidIcon: (
              <CheckCircleOutlineIcon
                fontSize="small"
                sx={{ color: "green", margin: "5px", fontSize: "15px" }}
              />
            ),
            InvalidIcon: (
              <CancelOutlinedIcon
                fontSize="small"
                sx={{ color: "grey", margin: "5px", fontSize: "15px" }}
              />
            ),
          }}
        />
      )}
    </>
  );
};

export default PasswordInput;
