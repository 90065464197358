import React from "react";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import HelpCenter from "../HelpCenter";
import "./Sidebar.css";

const theme = createTheme({
  typography: {
    fontFamily: "Plus Jakarta Sans, sans-serif",
  },
});

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    margin: theme.spacing(10),
    fontWeight: "bold",
    fontFamily: "Plus Jakarta Sans, sans-serif",
  },
  listItemText: {
    fontFamily: "Plus Jakarta Sans, sans-serif",
  },
  sidebarContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  content: {
    flexGrow: 1,
    overflowY: "auto",
  },
  helpCenter: {
    marginTop: "auto",
  },
}));

const Sidebar = ({ handleDrawerToggle }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.sidebarContainer}>
        <Toolbar>
          {handleDrawerToggle && (
            <IconButton
              color="inherit"
              aria-label="close drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Typography variant="h4" className={classes.logo}>
              Solulu
            </Typography>
          </Box>
        </Toolbar>
        <Box className={classes.content}>
          <List sx={{ maxWidth: "160px", marginLeft: "40px" }}>
            <ListItemButton
              href="/dashboard"
              sx={{
                "&:hover": {
                  backgroundColor: "grey.300",
                  color: "grey.900", 
                  borderRadius: "10px",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <WindowOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Overview"} />
            </ListItemButton>
            <ListItemButton
              href="/task"
              sx={{
                "&:hover": {
                  backgroundColor: "grey.300",
                  color: "grey.900",
                  borderRadius: "10px",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <MenuBookOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Task"} />
            </ListItemButton>
            <ListItemButton
              href="/mentors"
              sx={{
                "&:hover": {
                  backgroundColor: "grey.300",
                  color: "grey.900",
                  borderRadius: "10px",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <SupervisorAccountOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Mentors"} />
            </ListItemButton>
            <ListItemButton
              href="/messages"
              sx={{
                "&:hover": {
                  backgroundColor: "grey.300",
                  color: "grey.900",
                  borderRadius: "10px",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <EmailOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Messages"} />
            </ListItemButton>
            <ListItemButton
              href="/settings"
              sx={{
                "&:hover": {
                  backgroundColor: "grey.300",
                  color: "grey.900",
                  borderRadius: "10px",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <SettingsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Settings"} />
            </ListItemButton>
          </List>
        </Box>
        <Box className={classes.helpCenter}>
          <HelpCenter />
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default Sidebar;
