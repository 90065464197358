import { useState } from "react";
import axios from '../api/axios';

export default function useVerifyOtp() {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(null);

  const verifyOtp = async (otp, type) => {
    setIsLoading(true);
    setError(null);

    try {

      const credentials = {code: otp, type: type}

      const response = await axios.post("/user/verify", JSON.stringify(credentials), {
        withCredentials: true
      })
      .then(response => {
          if (response.status === 200) {
            setIsVerified(true);
            return response; 
          } else {
            throw new Error(response.msg || "Failed to verify OTP");
          }
      })

      return response?.data;

    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { verifyOtp, isLoading, isVerified, error };
}
