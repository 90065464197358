import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import EastIcon from '@mui/icons-material/East';
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Dropdown from "../components/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import PasswordInput from "../components/passwordInput";
import useResetPassword from "../hooks/ResetPasswordCheck";



export default function ResetPassword() {
  // eslint-disable-next-line
  const [selectedLanguage, setSelectedLanguage] = React.useState("EN");
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  // eslint-disable-next-line 
  const email = location.state?.email;
  const type = location.state?.type;
  const { resetPassword } = useResetPassword();

  React.useEffect(() => {
    if(type === undefined) {
      navigate('/forgot-password')
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isPasswordValid) {
      return;
    }

    if (password === confirmPassword) {
      resetPassword(password, confirmPassword).then((res) => {
        if (res.result === true) {
          navigate("/login", {
            state: {
              successMessage: "Password successfully changed! You may login!",
            },
          });
        }
      });
    }
  };

  

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setIsPasswordValid(e.target.value.length >= 8);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 4,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              container
              justifyContent="flex-end"
              sx={{ marginTop: "-20px" }}
            >
              <Grid item>
                <Dropdown setSelectedLanguage={setSelectedLanguage} />
              </Grid>
            </Grid>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 20 }}
            >
              <Typography
                component="h1"
                variant="h6"
                sx={{ alignItems: "left", width: "100%" }}
              >
                Reset Password
              </Typography>

              <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>Please type something you’ll remember</Typography>


              <InputLabel shrink={false} htmlFor={"password"} sx={{ mt: 4 }}>
                <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "800" }}>New Password*</Typography>
              </InputLabel>

              <PasswordInput
                idKey={"password"}
                password={password}
                handlePassword={handlePasswordChange}
              />

              <InputLabel shrink={false} htmlFor={"confirmPassword"} sx={{ mt: 4 }}>
                <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "800" }}>Confirm New Password*</Typography>
              </InputLabel>

              <PasswordInput
                idKey={"confirmPassword"}
                password={confirmPassword}
                checklistToggle={false}
                handlePassword={handleConfirmPasswordChange}
              />

              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  color: "white",
                  backgroundColor: "darkgrey",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white"
                  },
                  width: "100%",
                  alignItems: "center",
                }}
              >
                Reset Password
              </Button>

              <Grid container direction="row" justifyContent="start">
              <Grid item>
                  <Typography variant="body2" sx={{ fontSize: "14", fontWeight: "600", color: "#A8A8A8"}}>Remember Password?</Typography>
                </Grid>
                <Grid item sx={{ ml: 5}}>
                  <Link href="/login" variant="body2" underline="none">
                  <Typography sx={{ display: "flex", fontWeight: "600", color: "#000", alignItems: "center", fontSize: "14px" }}>
                    Login
                    <EastIcon sx={{ ml: 2 }}/>
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
  );
}
