import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import TaskBar from "../components/TaskBar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Headbar from "../components/Headbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";

const Dashboard = () => {
  const tasks = [
    {
      taskName: "Complete Project",
      taskDescription: "Finish the project by the end of the month.",
      progress: 75,
      imageUrl: "https://via.placeholder.com/300",
      daysToDeadline: 5,
      assignedTo: [
        {
          name: "John Doe",
          profilePhotoUrl: "https://via.placeholder.com/150",
        },
        {
          name: "Jane Smith",
          profilePhotoUrl: "https://via.placeholder.com/150",
        },
      ],
    },
    {
      taskName: "Write Report",
      taskDescription: "Prepare the monthly report for the team meeting.",
      progress: 50,
      imageUrl: "https://via.placeholder.com/300",
      daysToDeadline: 2,
      assignedTo: [
        {
          name: "Alice Johnson",
          profilePhotoUrl: "https://via.placeholder.com/150",
        },
      ],
    },
    {
      taskName: "Review Code",
      taskDescription: "Conduct a thorough code review.",
      progress: 60,
      imageUrl: "https://via.placeholder.com/300",
      daysToDeadline: 8,
      assignedTo: [
        {
          name: "Bob Brown",
          profilePhotoUrl: "https://via.placeholder.com/150",
        },
        {
          name: "Emily Clark",
          profilePhotoUrl: "https://via.placeholder.com/150",
        },
      ],
    },
    {
      taskName: "Design UI",
      taskDescription: "Create a new design for the user interface.",
      progress: 40,
      imageUrl: "https://via.placeholder.com/300", 
      daysToDeadline: 3,
      assignedTo: [
        {
          name: "David Lee",
          profilePhotoUrl: "https://via.placeholder.com/150",
        },
      ],
    },
    {
      taskName: "Test Application",
      taskDescription: "Run thorough tests on the application.",
      progress: 90,
      imageUrl: "https://via.placeholder.com/300", 
      daysToDeadline: 1,
      assignedTo: [
        {
          name: "Grace Taylor",
          profilePhotoUrl: "https://via.placeholder.com/150",
        },
        {
          name: "Michael Wilson",
          profilePhotoUrl: "https://via.placeholder.com/150",
        },
      ],
    },
  ];


  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: 240 }, flexShrink: { sm: 0 }, height: "100vh" }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
          }}
        >
          <Sidebar handleDrawerToggle={handleDrawerToggle} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
          }}
          open
        >
          <Sidebar />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflow: "auto",
          backgroundColor: '#FAFAFA'
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
            flexShrink: 0,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Headbar
            headerText="Skylar Dias"
            userSubtitle="Let's finish your task today"
            notificationsCount={4}
            profilePhotoUrl="https://via.placeholder.com/150"
          />
        </Box>
        <TaskBar tasks={tasks} />
      </Box>
    </Box>
  );
};

export default Dashboard;
