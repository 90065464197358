import React, { useState } from 'react';
import { Box, Grid, Pagination, Typography } from '@mui/material';
import TaskProgressCard from './TaskProgressCard';

const TaskBar = ({ tasks }) => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 2;
  const pageCount = Math.ceil(tasks.length / itemsPerPage);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleTasks = tasks.slice(startIndex, endIndex);

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
        <Typography variant="h6" color="text.secondary">
          Progress
        </Typography>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handleChangePage}
          color="primary"
          shape="rounded"
        />
      </Box>
      <Grid container spacing={3} justifyContent="center" marginTop={2}>
        
        {visibleTasks.map((task, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <TaskProgressCard
              taskName={task.taskName}
              taskDescription={task.taskDescription}
              progress={task.progress}
              imageUrl={task.imageUrl}
              daysToDeadline={task.daysToDeadline}
              assignedTo={task.assignedTo}
            />
          </Grid>
        ))}
      </Grid>
      
    </Box>
  );
};

export default TaskBar;
