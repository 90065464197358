import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function OtpTimer() {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  const resendOTP = () => {
    setMinutes(1);
    setSeconds(30);
  };

  return (
    <div className="container">
      <div
        className="card"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid
            item
            className="countdown-text"
            sx={{
              fontSize: "13px",
              fontWeight: 400,
              color: "#2d1d35",
            }}
          >
            {seconds > 0 || minutes > 0 ? (
              <Typography>
                Resend code in : 
                {" "}
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}

              </Typography>
            ) : (
              <Typography variant="body2">Didn't receive code?</Typography>
            )}
          </Grid>
          <Grid item>
            <button
              disabled={seconds > 0 || minutes > 0}
              style={{
                color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                border: "none",
                fontWeight: 500,
                textDecoration: "underline",
                cursor: "pointer",
                backgroundColor: "white",
              }}
              onClick={resendOTP}
            >
              Resend OTP
            </button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default OtpTimer;
