import React from "react";
import HomeHeader from "../components/HomeHeader";
import { Box } from "@mui/system";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import HomeTabs from "../components/HomeTabs";
import TextField from "@mui/material/TextField";

import {
  Grid,
  Typography,
  Container,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import PublicIcon from "@mui/icons-material/Public";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const logos = [
  "https://via.placeholder.com/100x50", 
  "https://via.placeholder.com/100x50", 
  "https://via.placeholder.com/100x50", 
  "https://via.placeholder.com/100x50", 
  "https://via.placeholder.com/100x50", 
];

const Home = () => {
  return (
    <div>
      <HomeHeader />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          textAlign: "center",
        }}
      >
        <h1>Access the Elite Tier of AI Engineering Talent</h1>
        <Box
          component="h5"
          sx={{
            maxWidth: "40%",
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          Solulu: Your gateway to elite AI talent. We connect top artificial
          intelligence engineers with innovative companies. From startups to
          global enterprises, businesses trust Solulu's experts to drive their
          most impactful tech initiatives forward.
        </Box>
        <Button
          sx={{
            color: "white",
            backgroundColor: "black",
            borderRadius: "20px",
            padding: "6px 12px",
            "&:hover": {
              backgroundColor: "#333",
            },
          }}
        >
          Scale Up with AI Professionals
          <NavigateNextRoundedIcon />
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
          textAlign: "center",
        }}
      >
        <Box variant="h1">BUSINESSES WORLDWIDE ❤️ SOLULU</Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            mt: 2,
          }}
        >
          {logos.map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt={`Business Logo ${index + 1}`}
              style={{ width: 100, height: 50 }}
            />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          textAlign: "center",
        }}
      >
        <h5>AUTOMATION</h5>
        <Box
          component="h2"
          sx={{
            maxWidth: "40%",
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          Powerful automation with zero code
        </Box>
        <Box
          component="h5"
          sx={{
            maxWidth: "50%",
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          Drive more revenue with sophisticated, end-to-end Workflows. Build
          brilliant customer journeys and keep customers happy with limitless
          payment options and commerce services.
        </Box>
        <Button
          sx={{
            color: "white",
            backgroundColor: "black",
            margin: "30px",
            borderRadius: "20px",
            padding: "6px 12px",
            "&:hover": {
              backgroundColor: "#333",
            },
          }}
        >
          Explore Automation
          <NavigateNextRoundedIcon />
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          textAlign: "center",
        }}
      >
        <HomeTabs />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          textAlign: "center",
          marginTop: "80px",
        }}
      >
        <h5>Payments</h5>
        <Box
          component="h2"
          sx={{
            maxWidth: "40%",
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          Your fully agnostic payment infrastructure
        </Box>
        <Box
          component="h5"
          sx={{
            maxWidth: "50%",
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          Integrate Primer once to boost your payment success forever. Create
          your own payment experience, then track and manage everything
          seamlessly across your payment processors.
        </Box>
      </Box>

      <Container maxWidth="lg">
        <Grid container spacing={6} justifyContent="center" sx={{ py: 4 }}>
          <Grid item xs={12} sm={3} textAlign="center">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                sx={{
                  backgroundColor: "#FFEBA4",
                  borderRadius: "50%",
                  display: "flex",
                  padding: 0.5,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  height: "40px",
                }}
              >
                <TrendingUpIcon fontSize="large" />
              </Box>
              <Typography sx={{ ml: 1, fontSize: "13px" }}>
                99.99% platform uptime
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={3} textAlign="center">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                sx={{
                  backgroundColor: "#FFEBA4",
                  borderRadius: "50%",
                  display: "flex",
                  padding: 0.5,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  height: "40px",
                }}
              >
                <SecurityIcon fontSize="large" />
              </Box>
              <Typography sx={{ ml: 1, fontSize: "13px" }}>
                PCI-1 & GDPR-compliant
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} textAlign="center">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                sx={{
                  backgroundColor: "#FFEBA4",
                  borderRadius: "50%",
                  display: "flex",
                  padding: 0.5,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  height: "40px",
                }}
              >
                <PublicIcon fontSize="large" />
              </Box>
              <Typography sx={{ ml: 1, fontSize: "13px" }}>
                Global coverage
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} textAlign="center">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                sx={{
                  backgroundColor: "#FFEBA4",
                  borderRadius: "50%",
                  display: "flex",
                  padding: 0.5,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  height: "40px",
                }}
              >
                <AccessTimeIcon fontSize="large" />
              </Box>
              <Typography sx={{ ml: 1, fontSize: "13px" }}>
                24/7 incident response
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box textAlign="left" sx={{ py: 4 }}>
          <Typography variant="h6" gutterBottom>
            No compromises. Our unified API and suite of tools optimize{" "}
            <br></br>
            payments, no matter which processor you use.
          </Typography>
        </Box>


        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Card
              sx={{
                backgroundColor: "#FF8C78",
                minHeight: "250px",
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardContent
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <PublicIcon fontSize="large" />
                </Box>
              </CardContent>
            </Card>

            <Card
              sx={{
                backgroundColor: "black",
                borderRadius: "15px",
                padding: "12px 12px",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ flexGrow: 1, textAlign: "left" }}>
                Unified Payments API
              </Typography>
              <ArrowUpwardIcon />
            </Card>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Card
              sx={{
                backgroundColor: "#E3E3E3",
                minHeight: "250px",
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardContent
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <PublicIcon fontSize="large" />
                </Box>
              </CardContent>
            </Card>
            <Card
              sx={{
                backgroundColor: "black",
                borderRadius: "15px",
                padding: "12px 12px",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ flexGrow: 1, textAlign: "left" }}>
                Adaptive 3D Secure
              </Typography>
              <ArrowUpwardIcon />
            </Card>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Card
              sx={{
                backgroundColor: "#FFEBA4",
                minHeight: "250px",
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardContent
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <PublicIcon fontSize="large" />
                </Box>
              </CardContent>
            </Card>
            <Card
              sx={{
                backgroundColor: "black",
                borderRadius: "15px",
                padding: "12px 12px",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ flexGrow: 1, textAlign: "left" }}>
                Network tokenization
              </Typography>
              <ArrowUpwardIcon />
            </Card>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Card
              sx={{
                backgroundColor: "#F2F2F1",
                minHeight: "250px",
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardContent
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <PublicIcon fontSize="large" />
                </Box>
              </CardContent>
            </Card>
            <Card
              sx={{
                backgroundColor: "black",
                borderRadius: "15px",
                padding: "12px 12px",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ flexGrow: 1, textAlign: "left" }}>
                Centralized vault
              </Typography>
              <ArrowUpwardIcon />
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{ my: 8, py: 8, backgroundColor: "#FAFAFA", borderRadius: "15px" }}
      >
        <Typography
          variant="h6"
          textAlign="left"
          gutterBottom
          sx={{ width: "40%" }}
        >
          Unlimited apps and integrations to grow your payment and commerce
          stack
        </Typography>
        <Box container spacing={4} justifyContent="">
          <Button
            variant="outlined"
            sx={{
              color: "black",
              borderColor: "black",
              borderRadius: "20px",
              padding: "6px 12px",
              "&:hover": {
                backgroundColor: "#333",
              },
              marginTop: "20px",
            }}
          >
            Explore Connections
            <NavigateNextRoundedIcon />
          </Button>
        </Box>
      </Container>
      <Container sx={{ mt: 4 }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                backgroundColor: "#F3ECFE",
                borderRadius: "20px",
                padding: 2,
                height: "600px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  <img
                    src="" // Replace
                    alt="Authorization Rates"
                    style={{ maxWidth: "100%", borderRadius: "10px" }}
                  />
                </Box>
                <Typography variant="h6" color="textPrimary" gutterBottom>
                  Authorization rates per processor
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Smash your goals with a clear source of truth across your
                  entire stack. Track and manage payments, reporting,
                  everything—from one beautiful dashboard.
                </Typography>
              </CardContent>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    mt: 2,
                    borderRadius: "20px",
                    textTransform: "none",
                  }}
                >
                  Learn more
                </Button>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card
              sx={{
                backgroundColor: "#ECFDF5",
                borderRadius: "20px",
                padding: 2,
                height: "600px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  <img
                    src="" // Replace
                    alt="Batches"
                    style={{ maxWidth: "100%", borderRadius: "10px" }}
                  />
                </Box>
                {/* Title */}
                <Typography variant="h6" color="textPrimary" gutterBottom>
                  Batches
                </Typography>
                {/* Description */}
                <Typography variant="body2" color="textSecondary">
                  Save countless hours and handle reconciliation seamlessly for
                  all your payment providers. Ditch those messy spreadsheets for
                  good, we’ve automated it all.
                </Typography>
              </CardContent>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    mt: 2,
                    borderRadius: "20px",
                    textTransform: "none",
                  }}
                >
                  Learn more
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          textAlign: "center",
        }}
      >
        <h1>Get the inside scoop from our merchants</h1>

        <Button
          varient="outlined"
          sx={{
            color: "black",
            borderColor: "black",
            border: 1,
            borderRadius: "20px",
            padding: "6px 12px",
            margin: "30px",
            "&:hover": {
              backgroundColor: "light grey",
            },
          }}
        >
          Read case studies
          <NavigateNextRoundedIcon />
        </Button>
      </Box>

      <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Product
              </Typography>
              <Typography>Universal checkout</Typography>
              <Typography>Payment workflows</Typography>
              <Typography>Obervability</Typography>
              <Typography>UpliftAI</Typography>
              <Typography>Apps & integrations</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Why Solulu
              </Typography>
              <Typography>Expand to new markets</Typography>
              <Typography>Boost payment success</Typography>
              <Typography>Improve conversion rates</Typography>
              <Typography>Reduce payments fraud</Typography>
              <Typography>Recover revenue</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Developers
              </Typography>
              <Typography>Solulu Docs</Typography>
              <Typography>API Reference</Typography>
              <Typography>Payment methods guide</Typography>
              <Typography>Obervability</Typography>
              <Typography>Service status</Typography>
              <Typography>Community</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Resources
              </Typography>
              <Typography>Blog</Typography>
              <Typography>Success stories</Typography>
              <Typography>News room</Typography>
              <Typography>Privacy</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Company
              </Typography>
              <Typography>Careers</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Stay up to date
              </Typography>

              <Box my={2} display="flex" alignItems="center">
                <TextField
                  label="Email Address"
                  variant="outlined"
                  size="small"
                  sx={{ flexGrow: 1 }}
                />
                <Button variant="contained" sx={{ ml: 2, backgroundColor: "black" }}>
                  Subscribe
                </Button>
              </Box>

              <Typography>
                I confirm that I have read Solulu's Privacy policy and agree
                with it.
              </Typography>
            </Grid>
          </Grid>

          <Box mt={4} textAlign="center">
            <Typography variant="body2">
              &copy; {new Date().getFullYear()} Solulu. All rights
              reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Home;
