import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Divider from "@mui/material/Divider";
import EastIcon from '@mui/icons-material/East';
import { useState } from "react";
import PasswordInput from "../components/passwordInput";
import GoogleIcon from "@mui/icons-material/Google";
import Dropdown from "../components/Dropdown";
import XIcon from "@mui/icons-material/X";
import GitHubIcon from "@mui/icons-material/GitHub";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import UsernameValidation from "../hooks/UsernameValidation";
import EmailValidation from "../hooks/EmailValidation";
import RegisterHook from "../hooks/RegisterHook";
import { Snackbar, Alert } from "@mui/material";

export default function Register() {
  // eslint-disable-next-line
 const [selectedLanguage, setSelectedLanguage] = React.useState("EN");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const { isUsernameAvailable } = UsernameValidation(username);
  const { isEmailAvailable } = EmailValidation(email);
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const { errorMessage, registerUser } = RegisterHook();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!username || !email || !password) {
      return;
    }

    if (!isPasswordValid) {
      return;
    }

    const userData = {
      username,
      email,
      password,
      role: "user",
    };

    if(isUsernameAvailable) {
      registerUser(userData).then((res) => {
        if (res.result === true) {
          navigate("/login", {
            state: {
              successMessage: "Registration successful! You can now log in.",
            },
          });
        } else {
          setOpenSnackbar(true);
        }
      });
    }

  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setIsPasswordValid(e.target.value.length >= 8);
  };

  return (
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 4,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              container
              justifyContent="flex-end"
              sx={{ marginTop: "-20px" }}
            >
              <Grid item>
                <Dropdown setSelectedLanguage={setSelectedLanguage} />
              </Grid>
            </Grid>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 20 }}
            >
              <Typography
                component="h1"
                variant="h6"
                sx={{ alignItems: "left", width: "100%" }}
              >
                Create an account
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>Join Solulu.xyz</Typography>
              <InputLabel shrink={false} htmlFor={"username"} sx={{ mt: 4 }}>
                <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "800" }}>Username*</Typography>
              </InputLabel>
              <TextField
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                placeholder="from 3 to 16 alpha-numeric characters"
                sx={{
                  width: "35ch",
                  backgroundColor: "#F5F5F5",
                  borderRadius: "4px",
                  padding: 1,
                  border: isUsernameAvailable === false ? 1 : undefined,
                  borderColor:
                    isUsernameAvailable === false ? "red" : undefined,
                }}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  sx: { fontSize: "small" },
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: { fontSize: "small" },
                }}
                name="username"
                autoComplete="username"
                size="small"
              />
              {isUsernameAvailable === false && (
                <li style={{ color: "red", fontSize: "10px" }}>
                  This username is already taken. Please try with another
                  username.
                </li>
              )}

              <InputLabel shrink={false} htmlFor={"email"} sx={{ mt: 1 }}>
                <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "800" }}>Email*</Typography>
              </InputLabel>
              <TextField
                id="email"
                value={email}
                placeholder="example@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
                required
                sx={{
                  width: "35ch",
                  backgroundColor: "#F5F5F5",
                  borderRadius: "4px",
                  padding: 1,
                  border: isEmailAvailable === true ? 1 : undefined,
                  borderColor: isEmailAvailable === true ? "red" : undefined,
                }}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  sx: { fontSize: "small" },
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: { fontSize: "small" },
                }}
                name="email"
                autoComplete="email"
                size="small"
              />
              {isEmailAvailable === true && (
                <li style={{ color: "red", fontSize: "10px" }}>
                  This email address has already been registered.
                </li>
              )}

              <InputLabel shrink={false} htmlFor={"password"} sx={{ mt: 1 }}>
                <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "800" }}>Password*</Typography>
              </InputLabel>

              <PasswordInput
                password={password}
                handlePassword={handlePasswordChange}
              />

              <Grid container direction="row" justifyContent="space-between">
                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      icon={
                        <span
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            border: "1px solid black",
                            display: "inline-block",
                            width: 20,
                            height: 20,
                          }}
                        />
                      }
                      checkedIcon={
                        <span
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "#000",
                            display: "inline-block",
                            width: 20,
                            height: 20,
                            textAlign: "center",
                            color: "white",
                          }}
                        >
                          <DoneIcon fontSize="small" />
                        </span>
                      }
                    />
                  }
                  label={
                    <Typography variant="body2">
                      I accept the terms and privacy policy
                    </Typography>
                  }
                />
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  color: "white",
                  backgroundColor: "grey",
                  "&:hover": {
                    backgroundColor: "darkgrey",
                  },
                }}
              >
                Register
              </Button>

              {errorMessage && (
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={5000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                  <Alert onClose={handleCloseSnackbar} severity="error">
                    {errorMessage}
                  </Alert>
                </Snackbar>
              )}
             
             <Grid container direction="row" justifyContent="start">
                <Grid item>
                  <Typography variant="body2" sx={{ fontSize: "14", fontWeight: "600", color: "#A8A8A8"}}>Already have an account?</Typography>
                </Grid>
                <Grid item sx={{ ml: 5}}>
                  <Link href="/login" variant="body2" underline="none">
                  <Typography sx={{ display: "flex", fontWeight: "600", color: "#000", alignItems: "center", fontSize: "14px" }}>
                    Login
                    <EastIcon sx={{ ml: 2 }}/>
                    </Typography>
                  </Link>
                </Grid>
              </Grid>

              <Divider style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 1,
                }}
              >
                <IconButton
                  sx={{
                    border: "1px solid #000",
                    borderRadius: "50%",
                    margin: 1,
                  }}
                  fontSize="small"
                >
                  <GoogleIcon />
                </IconButton>
                <IconButton
                  sx={{
                    border: "1px solid #000",
                    borderRadius: "50%",
                    margin: 1,
                  }}
                >
                  <XIcon />
                </IconButton>
                <IconButton
                  sx={{
                    border: "1px solid #000",
                    borderRadius: "50%",
                    margin: 1,
                  }}
                >
                  <GitHubIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Grid>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
  );
}
